import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as actions from "../actions";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/react";
import LoadingComponent from "../components/LoadingComponent";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: blue;
`;

const Login = () => {
  let [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");

  if (loading) {
    return <LoadingComponent />;
  }

  if (!("mediaDevices" in navigator)) {
    // alert("Your browser/device does not support for videokyc.");
    return (
      <Container>
        <Row>
          <Col
            xs={12}
            sm={{ span: 8, offset: 2 }}
            md={{ span: 6, offset: 3 }}
            lg={{ span: 4, offset: 4 }}
            style={{ paddingTop: "40vh" }}
          >
            Your browser/device does not support for VideoKYC.
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <Col
          xs={12}
          sm={{ span: 8, offset: 2 }}
          md={{ span: 6, offset: 3 }}
          lg={{ span: 4, offset: 4 }}
          style={{ paddingTop: "20vh" }}
        >
          <Form>
            <h4 style={{ marginBottom: 20 }}>Login</h4>
            <Form.Group controlId="formBasicEmail">
              <Form.Control
                type="text"
                value={username}
                placeholder="Enter mobile"
                maxLength="10"
                onChange={(e) => {
                  console.log(e);
                  setUsername(e.target.value);
                }}
              />
              <Form.Text className="text-muted">
                We'll never share your mobile with anyone else.
              </Form.Text>
            </Form.Group>
            <Button
              variant="secondary btn btn-block"
              type="submit"
              onClick={async (e) => {
                e.preventDefault();
                if (username.length != 10) {
                  alert("Enter correct mobile number");
                  return;
                }
                setLoading(true);
                const response = await actions.authSendOTP({username: username});
                if (response) {
                  window.location.href = "/verify";
                } else {
                  setLoading(false);
                  alert("Some problem occurred.");
                }
              }}
            >
              Send OTP
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
