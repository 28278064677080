import React, { useState } from "react";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/react";
import { Container } from "react-bootstrap";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: blue;
`;

const LoadingComponent = () => {
  let [color, setColor] = useState("#777");

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ height: "90vh" }}
    >
      <div>
        <ClipLoader color={color} size={40} />
      </div>
      
    </Container>
  );
};

export default LoadingComponent;
