import React, { Component } from "react";
import { Container, Row, Col, Form, Button, Image, ProgressBar } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom";
import profile from "../media/dummy_profile.png";
//import * as actions from "../actions";
import LoadingComponent from "../components/LoadingComponent";
import CameraLoader from "../components/CameraLoader";
import * as constants from "../constants";
import axios from "axios";
class Agreement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cameraOn: false,
      latitude: null,
      longitude: null,
      photoBlob: null,
      termsChecked: false,
      loading: true,
      cameraLoading: true,
      isUploading: false,
      now: 0.0,
      uploadStarted: false,
    };

    this.imageRef = React.createRef();
    this.squareRef = React.createRef();
  
  }

  rect(props) {
    const {ctx, x, y, width, height} = props;
    // ctx.beginPath();
    ctx.strokeRect(x, y, width, height);
    ctx.stroke();
  }

  updateCanvas() {
      const ctx = this.refs.canvas.getContext('2d');
      ctx.clearRect(0,0, 300, 300);
      // draw children “components”
      // ctx.globalAlpha = 0.2;
      ctx.strokeStyle = "#00FF00";
      this.rect({ctx, x: 30, y: 50, width: 240, height: 200});
  }

  componentDidMount() {
    this.getCamera(this);
    this.setState({ loading: false });
    setTimeout(function(){
      this.updateCanvas()
    }.bind(this), 1000);

    // const canvas = this.squareRef.current;
    // console.log(this.squareRef);
    // canvas.height = 300;
    // canvas.width = 300;
    // const ctx = canvas.getContext("2d");
    // ctx.beginPath();
    // ctx.rect(20, 20, 150, 100);
    // ctx.stroke();
  }

  submitRecord = async (formdata) => {
    
  };  

  getCamera = (parent) => {
    this.setState({ cameraLoading: true });
    // const permissionStatus = await navigator.permissions.query({ name: "geolocation" });
    // console.log(permissionStatus);
    // if (permissionResponse.state === "denied") {
    //   alert("For KYC location permissions are necessary.");
    // }
    // var notification = new Notification("Hi there!");
    // navigator.mediaDevices.getUserMedia({video: true})
    //           .then((stream) => {
    //             console.log(stream);
    //             // parent.setState({ cameraOn: true })
    //             // // console.log(stream);
    //             // const player = document.getElementById('player');
    //             // player.srcObject = stream;
    //           });
    var options = {
      enableHighAccuracy: true,
      timeout: 15000,
      maximumAge: 0,
    };

    // function success(pos) {
    //   var crd = pos.coords;

    //   console.log('Your current position is:');
    //   console.log(`Latitude : ${crd.latitude}`);
    //   console.log(`Longitude: ${crd.longitude}`);
    //   console.log(`More or less ${crd.accuracy} meters.`);
    // }

    //For Geolocation
    function error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
      alert("Please unblock geolocation and try again");
    }

    const constraints = {
      video: true,
    };
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log(position);
        this.setState({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      
        //For Camera 


        navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
          parent.setState({ cameraOn: true, cameraLoading: false });
          // console.log(stream);
          const player = document.getElementById("player");
          player.srcObject = stream;
        },
        errorCallback,
        options);
        
        var errorCallback = function(error) {
          console.log("_____________________________");
          console.log(error.name||error);
          alert("Please unblock the camera and try again!");
          
          // if ((error.name == 'NotAllowedError') ||
          //   (error.name == 'PermissionDismissedError')) {
          //   detectPermissionDialog(false);
          // }
            
        };

      },
      error,
      options
      
    );

    
  /*

 navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      parent.setState({ cameraOn: true, cameraLoading: false });
      // console.log(stream);
      const player = document.getElementById("player");
      player.srcObject = stream;
    },
    error,
    options);
        
*/

    // navigator.permissions.query({ name: 'geolocation' })
    //   .then(function (permissionStatus) {
    //     console.log('geolocation permission state is ', permissionStatus.state);
    //     switch (permissionStatus.state) {
    //       case "granted":
    //         var options = {
    //           enableHighAccuracy: true,
    //           timeout: 15000,
    //           maximumAge: 0
    //         };

    //         function success(pos) {
    //           var crd = pos.coords;

    //           console.log('Your current position is:');
    //           console.log(`Latitude : ${crd.latitude}`);
    //           console.log(`Longitude: ${crd.longitude}`);
    //           console.log(`More or less ${crd.accuracy} meters.`);
    //         }

    //         function error(err) {
    //           console.warn(`ERROR(${err.code}): ${err.message}`);
    //         }

    //         navigator.geolocation.getCurrentPosition(success, error, options);
    //         const constraints = {
    //           video: true,
    //         };

    //         navigator.mediaDevices.getUserMedia(constraints)
    //           .then((stream) => {
    //             parent.setState({ cameraOn: true })
    //             // console.log(stream);
    //             const player = document.getElementById('player');
    //             player.srcObject = stream;
    //           });
    //         break;
    //       case "denied":
    //       case "prompt":
    //         alert("You do not have location permissions.");
    //         parent.setState({ cameraOn: false })
    //         break;
    //     }

    //     permissionStatus.onchange = function () {
    //       console.log('geolocation permission state has changed to ', this.state);
    //       switch (this.state) {
    //         case "granted":
    //           break;
    //         case "denied":
    //         case "prompt":
    //           alert("You do not have location permissions.");
    //           parent.setState({ cameraOn: false })
    //           break;
    //       }
    //     };
    //   });
  };

  render() {
    const {
      cameraOn,
      latitude,
      longitude,
      photoBlob,
      termsChecked,
      loading,
      cameraLoading,
      uploadStarted,
      now,
      isUploading,
    } = this.state;
    const that = this;

    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (percentCompleted < 100) {
          that.setState({ now: percentCompleted });
        } else {
          that.setState({ now: 0, isUploading: false });
        }
        console.log(percentCompleted);
      },
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("token"),
      },
    };
    if (uploadStarted) {
      const { now } = this.state;
      return (
        <Container
          className="d-flex align-items-center justify-content-center"
          style={{ height: "90vh" }}
        >
          <div>
            <ProgressBar
              style={{ marginTop: 10, marginBottom: 10 }}
              variant="success"
              now={now}
              label={`${now}%`}
            />
            <p className="text-center">
              Do not refresh or close the page. Please wait...
            </p>
          </div>
        </Container>
      );
    }
    if (loading) {
      return <LoadingComponent />;
    }

    return (
      <Container>
        <Row>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 8, offset: 2 }}
            md={{ span: 6, offset: 3 }}
            lg={{ span: 4, offset: 4 }}
            style={{ paddingTop: "5vh" }}
          >
            <h4 style={{ marginBottom: 20 }}>Agreement</h4>
            <p style={{ textAlign: "justify", fontSize: 12 }}>
              I affirm and declare that I have read over and understood the
              rules and regulations of the “Bank” and those relating to various
              services offered by the Bank including but not limiting to debit
              card/internet banking/SMS banking/Tele-Banking/Mobile
              Banking/Virtual Banking and any other facilities. I agree to abide
              by the same as amended/modified from time to time by the
              Bank/Regulator/Government published through circulars,
              notifications, notice board/websites/newspaper publications, etc.
              I waive the rights, if any, to have personal notice in respect of
              such amendments/modifications. I agree that the transactions and
              requests executed in my account(s) through internet, mobile,
              tele-banking or virtual banking under my User ID and
              password/PIN/OTP will be legally binding on me & I am responsible
              for the maintenance of secrecy and confidentiality of the
              authentication credentials and any other
              information/details/OTP/PIN, etc., in such matters. I agree that
              Bank has got all the rights to debit my account for any service
              charge, expenses or other dues which the Bank is entitled/ liable
              to recover from me. I also authorize the Bank and agree to
              close/discontinue my account without any notice to me(under normal
              circumstance , bank will not close account without giving 30 days’
              notice indicating reason for closure).I hereby undertake to inform
              the Bank on any change in my communication address or
              constitution. I confirm and declare that I am not
              prevented/prohibited/restricted by any applicable
              legal/regulatory/contractual or other provisions from opening
              and/or maintaining the accounts or to transact with the Bank in
              any other way. I agree that my personal KYC details may be shared
              with Central KYC registry or any other competent authority. I
              hereby consent to receive information from the Bank/Central KYC
              Registry/GoI/RBI or any other authority through SMS/e-mail on my
              registered mobile number/ e-mail address. I also agree that the
              non-receipt of any such SMS/e-mail shall not make the Bank liable
              for any loss or damage whatsoever in nature
            </p>
            <Form className="my-2">
              <h5>Take your Photo</h5>
              <div
                style={{
                  width: 300,
                  height: 300,
                  display: cameraLoading ? "block" : "none",
                }}
              >
                <CameraLoader />
              </div>
              <canvas
                ref="canvas"
                width={300}
                height={300}
                style={{position: 'absolute', width: 300, height: 300}}
              >
              </canvas>
              <div style={{ display: cameraLoading ? "none" : "block" }}>
                <canvas
                  ref={this.imageRef}
                  src={profile}
                  style={{
                    width: 300,
                    height: 300,
                    display: !cameraOn ? "block" : "none",
                  }}
                  onClick={() => {
                    this.getCamera(this);
                  }}
                />
                <video
                  id="player"
                  autoPlay
                  style={{
                    width: 300,
                    height: 300,
                    display: !cameraOn ? "none" : "block",
                  }}
                ></video>
              </div>
              <br />
              <div>
                {isUploading && (
                  <ProgressBar
                    style={{ marginTop: 10, marginBottom: 10 }}
                    variant="success"
                    now={now}
                    label={`${now}%`}
                  />
                )}
              </div>
              <br />
              <Button
                className="btn btn-sm"
                style={{ display: cameraOn ? "block" : "none" }}
                onClick={() => {
                  // const canvas = document.getElementById('canvas');
                  const canvas = this.imageRef.current;
                  console.log(this.imageRef.current);
                  const player = document.getElementById("player");
                  canvas.height = 300;
                  canvas.width = 300;
                  const context = canvas.getContext("2d");
                  
                  context.drawImage(player, 0, 0, canvas.width, canvas.height);
                  // // 
                  // context.beginPath();
                  // context.rect(20, 20, 150, 100);
                  // context.stroke();
                  // // 
                  player.srcObject
                    .getVideoTracks()
                    .forEach((track) => track.stop());
                  const that = this;
                  canvas.toBlob(function (blob) {
                    that.setState({
                      cameraOn: false,
                      photoBlob: blob,
                    });
                  });
                }}
              >
                Capture Image
              </Button>
              <Button
                className="btn btn-sm"
                style={{ display: cameraOn ? "none" : "block" }}
                onClick={() => {
                  this.getCamera(this);
                }}
              >
                Recapture Image
              </Button>
            </Form>
            <Form.Group controlId="formBasicCheckbox">
              <Form.Check
                checked={termsChecked}
                onChange={(e) => {
                  console.log("terms", termsChecked, e.target.checked);
                  this.setState({
                    termsChecked: e.target.checked,
                  });
                }}
                type="checkbox"
                label="I have read the terms and conditions, I thereby agree to the same"
              />
            </Form.Group>
            <Link to={`/VideoKyc`}>
              <Button
                className="my-4"
                variant="secondary btn btn-block"
                type="submit"
                onClick={async (formdata) => {
                  formdata.preventDefault();
                  let that = this;
                  const config = {
                    onUploadProgress: function (progressEvent) {
                      var percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                      );
                      if (percentCompleted < 100) {
                        that.setState({ now: percentCompleted });
                      } else {
                        that.setState({ now: 0, isUploading: false, uploadStarted: false });
                      }
                      console.log(percentCompleted);
                    },
              
                    headers: {
                      "Content-Type": "multipart/form-data",
                      Authorization: localStorage.getItem("token"),
                    },
                  };
                  const token = localStorage.getItem("token");
                  if (latitude == null || longitude == null) {
                    alert("You haven't enabled the location");
                    this.setState({ loading: false, uploadStarted: false });
                  } else if (photoBlob == null) {
                    alert("You haven't took a photo yet");
                    this.setState({ loading: false, uploadStarted: false });
                  } else if (!termsChecked) {
                    alert("Please agree terms and conditions");
                  } else {
                    this.setState({uploadStarted:true});
                    this.setState({ loading: true , isUploading: true});
                    var formData = new FormData();
                    formData.append("latitude", latitude);
                    formData.append("longitude", longitude);
                    formData.append("photo", photoBlob);
                    const response = await axios.post(
                      "/api/video/submitrecord/",
                      formData,
                      config
                    );
                    if (response.status == 200) {
                      this.setState({ isUploading : false, uploadStarted: false });
                      //alert("Successful");
                      window.location.href = "/videokyc";
                    } else {
                      this.setState({ loading: false, uploadStarted: false });
                      alert("Photo Upload Unsuccessful");
                    }
                  }
                  
                  //console.log(formdata, token); 
                }}
              >
                Proceed
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Agreement;
