import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  ProgressBar,
  Alert,
  Modal,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import profile from "../media/dummy_profile.png";
import * as actions from "../actions";
import axios from "axios";
import LoadingComponent from "../components/LoadingComponent";
import CameraLoader from "../components/CameraLoader";
import Countdown from "react-countdown";

const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return "";
  } else {
    // Render a countdown
    return <span style={{ color: "#ff0000" }}>{seconds} seconds left</span>;
  }
};

class VideoKyc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisconnected: false,
      cameraOn: false,
      mediaBlob: null,
      isVideoRecording: false,
      termsChecked: false,
      isUploading: false,
      // command for automatic submit
      videoSend: false,
      latitude: null,
      longitude: null,
      now: 0,
      loading: true,
      cameraLoading: true,
      uploadStarted: false,
      localstream: null,
      aadharFrontShow: false,
      aadharBackShow: false,
      panShow: false,
      askForDetails: false,
      disable: false,
      show: false,
      counter: 8,
      start: false,
    };

    this.imageRef = React.createRef();
  }

  rect(props) {
    const { ctx, x, y, width, height } = props;
    // ctx.beginPath();
    ctx.strokeRect(x, y, width, height);
    ctx.stroke();
  }

  updateCanvas() {
    const ctx = this.refs.canvas.getContext("2d");
    ctx.clearRect(0, 0, 300, 300);
    // draw children “components”
    // ctx.globalAlpha = 0.2;
    ctx.strokeStyle = "#00FF00";
    this.rect({ ctx, x: 30, y: 50, width: 240, height: 200 });
  }

  componentDidMount() {
    this.setState({ loading: false });
    this.getCamera(this);
    this.handleConnectionChange();
    window.addEventListener("online", this.handleConnectionChange);
    window.addEventListener("offline", this.handleConnectionChange);
    setTimeout(
      function () {
        this.updateCanvas();
      }.bind(this),
      1000
    );
  }

  componentWillUnmount() {
    window.removeEventListener("online", this.handleConnectionChange);
    window.removeEventListener("offline", this.handleConnectionChange);
  }

  handleConnectionChange = () => {
    const { videoSend } = this.state;

    const condition = navigator.onLine ? "online" : "offline";
    if (condition === "online") {
      const webPing = setInterval(() => {
        fetch("//google.com", {
          mode: "no-cors",
        })
          .then(() => {
            this.setState({ isDisconnected: false }, () => {
              console.log("Network on");
              if (videoSend) {
                this.setState({ uploadStarted: true });
                this.submitVideo();
                console.log("Submitting....");
              } else {
                this.setState({ loading: false, uploadStarted: false });
              }
              return clearInterval(webPing);
            });
          })
          .catch(() => this.setState({ isDisconnected: true }));
      }, 2000);
      return;
    }

    return this.setState({ isDisconnected: true });
  };

  submitVideo = async () => {
    const that = this;
    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (percentCompleted < 100) {
          that.setState({ now: percentCompleted });
        } else {
          that.setState({ now: 0, isUploading: false, uploadStarted: false });
        }
        console.log(percentCompleted);
      },
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("token"),
      },
    };

    const { mediaBlob, termsChecked, latitude, longitude, localstream } =
      this.state;
    console.log("submitting.........");

    if (mediaBlob == null) {
      alert("You haven't recorded any video yet");
      that.setState({ loading: false, uploadStarted: false });
      // } else if (!termsChecked) {
      //   alert("Please agree terms and conditions");
      //   that.setState({ loading: false, uploadStarted: false });
    } else {
      that.setState({ isUploading: true, now: 0 });
      var formData = new FormData();
      formData.append("video", mediaBlob);
      formData.append("latitude", latitude);
      formData.append("longitude", longitude);
      const token = localStorage.getItem("token");
      const response = await axios.post(
        "/api/video/submitrecord/",
        formData,
        config
      );
      if (response.status == 200) {
        that.setState({ videoSend: false, uploadStarted: false });
        // console.log(this.localstream.getVideoTracks());
        //this.MediaStreamTrack.stop()
        // var tracks = this.localstream.getTracks();
        // tracks.forEach(function (track) {
        //   console.log(track);
        //   track.stop();
        // });
        // that.setState({ localstream: null });
        that.setState({ show: true });
        localStorage.removeItem("token");
        localStorage.removeItem("username");
      } else {
        that.setState({ loading: false, uploadStarted: false });
        alert("Problem occurred while uploading video.");
      }
    }
  };

  getCamera = (parent) => {
    this.setState({ cameraLoading: true });
    // const permissionStatus = await navigator.permissions.query({ name: "geolocation" });
    // console.log(permissionStatus);
    // if (permissionResponse.state === "denied") {
    //   alert("For KYC location permissions are necessary.");
    // }

    var options = {
      enableHighAccuracy: true,
      timeout: 15000,
      maximumAge: 0,
    };

    function error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
      alert(
        "There was some error. Please check you microphone and permissions"
      );
    }

    const constraints = {
      video: true,
      audio: true,
    };

    navigator.geolocation.getCurrentPosition(
      (position) => {
        // console.log(position);
        this.setState({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
        navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
          parent.setState({
            cameraOn: true,
            cameraLoading: false,
            localstream: stream,
          });

          // console.log(stream);
          const player = document.getElementById("player");
          player.srcObject = stream;
        });
      },
      error,
      options
    );

    // navigator.permissions.query({ name: 'geolocation' })
    //     .then(function (permissionStatus) {
    //         console.log('geolocation permission state is ', permissionStatus.state);
    //         switch (permissionStatus.state) {
    //             case "granted":
    //                 var options = {
    //                     enableHighAccuracy: true,
    //                     timeout: 15000,
    //                     maximumAge: 0
    //                 };

    //                 function success(pos) {
    //                     var crd = pos.coords;

    //                     console.log('Your current position is:');
    //                     console.log(`Latitude : ${crd.latitude}`);
    //                     console.log(`Longitude: ${crd.longitude}`);
    //                     console.log(`More or less ${crd.accuracy} meters.`);
    //                 }

    //                 function error(err) {
    //                     console.warn(`ERROR(${err.code}): ${err.message}`);
    //                 }

    //                 navigator.geolocation.getCurrentPosition(success, error, options);
    //                 const constraints = {
    //                     video: true,
    //                     audio: true
    //                 };

    //                 navigator.mediaDevices.getUserMedia(constraints)
    //                     .then((stream) => {
    //                         parent.setState({ cameraOn: true })
    //                         // console.log(stream);
    //                         const player = document.getElementById('player');
    //                         player.srcObject = stream;
    //                     });
    //                 break;
    //             case "denied":
    //             case "prompt":
    //                 alert("You do not have location permissions.");
    //                 parent.setState({ cameraOn: false })
    //                 break;
    //         }

    //         permissionStatus.onchange = function () {
    //             console.log('geolocation permission state has changed to ', this.state);
    //             switch (this.state) {
    //                 case "granted":
    //                     break;
    //                 case "denied":
    //                 case "prompt":
    //                     alert("You do not have location permissions.");
    //                     parent.setState({ cameraOn: false })
    //                     break;
    //             }
    //         };
    //     });
  };

  // stopCamera = () => {
  //   navigator.getUserMedia(
  //     { audio: true, video: true },
  //     function (stream) {
  //       // can also use getAudioTracks() or getVideoTracks()
  //       console.log(stream.getTracks());
  //       let tracks = stream.getTracks();
  //       for (let i in tracks) {
  //         console.log(i);
  //       }
  //       // var track = stream.getTracks()[0]; // if only one media track
  //       // // ...
  //       // track.stop();
  //     },
  //     function (error) {
  //       console.log("getUserMedia() error", error);
  //     }
  //   );
  // };

  recordVideo = (parent) => {
    console.log("video recording started...");

    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(function (stream) {
        let shouldStop = false;
        let stopped = false;
        // const downloadLink = document.getElementById("download");
        const stopButton = document.getElementById("stop");
        stopButton.addEventListener("click", function () {
          shouldStop = true;
          console.log("video recording stopped...");
          console.log(shouldStop, stopped);
          if (shouldStop && !stopped) {
            console.log("shouldStop");
            mediaRecorder.stop();
            stopped = true;
          }
        });
        function getSupportedMimeTypes() {
          const VIDEO_TYPES = ["webm", "ogg", "mp4", "x-matroska"];
          const VIDEO_CODECS = [
            "vp9",
            "vp9.0",
            "vp8",
            "vp8.0",
            "avc1",
            "av1",
            "h265",
            "h.265",
            "h264",
            "h.264",
            "opus",
          ];

          const supportedTypes = [];
          VIDEO_TYPES.forEach((videoType) => {
            const type = `video/${videoType}`;
            VIDEO_CODECS.forEach((codec) => {
              const variations = [
                `${type};codecs=${codec}`,
                `${type};codecs:${codec}`,
                `${type};codecs=${codec.toUpperCase()}`,
                `${type};codecs:${codec.toUpperCase()}`,
                `${type}`,
              ];
              variations.forEach((variation) => {
                if (MediaRecorder.isTypeSupported(variation))
                  supportedTypes.push(variation);
              });
            });
          });
          return supportedTypes;
        }

        console.log(stream);
        const options = getSupportedMimeTypes();
        console.log("Best supported mime types by priority : ", options[0]);
        console.log("All supported mime types ordered by priority : ", options);
        const recordedChunks = [];
        const mediaRecorder = new MediaRecorder(stream, options);
        console.log(mediaRecorder);

        mediaRecorder.ondataavailable = (e) => {
          console.log("Data", e.data);
          if (e.data.size > 0) {
            recordedChunks.push(e.data);
          }
        };

        mediaRecorder.onstop = () => {
          //   downloadLink.href = URL.createObjectURL(new Blob(recordedChunks));
          //   downloadLink.download = "acetest.webm";

          console.log("shouldStop");
          console.log(stream.getTracks());
          console.log(new Blob(recordedChunks));
          parent.setState({
            mediaBlob: new Blob(recordedChunks),
            localstream: stream,
          });
        };

        mediaRecorder.onerror = (e) => {
          console.log(e);
        };

        mediaRecorder.onstart = () => {
          console.log("started");
          parent.setState({ isVideoRecording: true });
        };

        mediaRecorder.start();
        console.log(mediaRecorder);
      });
  };

  render() {
    const {
      cameraOn,
      mediaBlob,
      isVideoRecording,
      termsChecked,
      isUploading,
      now,
      isDisconnected,
      loading,
      cameraLoading,
      uploadStarted,
      aadharFrontShow,
      aadharBackShow,
      panShow,
      askForDetails,
      disable,
      show,
      counter,
      start,
    } = this.state;

    const that = this;

    let startInterval = () => {
      const x = setInterval(() => {
        if (counter != 0) {
          this.setState({ counter: counter - 1 });
        } else {
          clearInterval(x);
          this.setState({ counter: 0 });
        }
      }, 1000);
    };

    const indications = () => {
      this.setState({ aadharFrontShow: true });

      setTimeout(
        () =>
          this.setState({
            aadharFrontShow: false,
            aadharBackShow: true,
            counter: 8,
          }),
        8000
      );

      setTimeout(
        () =>
          this.setState({ aadharBackShow: false, panShow: true, counter: 8 }),
        16000
      );
      setTimeout(
        () =>
          this.setState({ panShow: false, askForDetails: true, start: false }),
        24000
      );
    };

    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (percentCompleted < 100) {
          that.setState({ now: percentCompleted });
        } else {
          that.setState({ now: 0, isUploading: false });
        }
        console.log(percentCompleted);
      },
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("token"),
      },
    };

    if (loading) {
      return <LoadingComponent />;
    }

    if (uploadStarted) {
      const { now } = this.state;
      return (
        <Container
          className="d-flex align-items-center justify-content-center"
          style={{ height: "90vh" }}
        >
          <div>
            <ProgressBar
              style={{ marginTop: 10, marginBottom: 10 }}
              variant="success"
              now={now}
              label={`${now}%`}
            />
            <p className="text-center">
              Do not refresh or close the page. Please wait...
            </p>
          </div>
        </Container>
      );
    }

    return (
      <Container>
        <Row>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 8, offset: 2 }}
            md={{ span: 6, offset: 3 }}
            lg={{ span: 4, offset: 4 }}
            style={{ paddingTop: "5vh" }}
          >
            <Alert variant="danger">
              You are {isDisconnected ? "offline" : "online"}
            </Alert>
            <h4 style={{ marginBottom: 20 }}>VideoKYC</h4>
            <p
              style={{
                textAlign: "justify",
                fontWeight: "bold",
                color: "green",
              }}
            >
              Please keep your original (physical) PAN and Aadhar Card ready
              with you before recording the video.
            </p>
            <p
              style={{
                textAlign: "justify",
                fontWeight: "bold",
                color: "green",
              }}
            >
              Hold the cards properly such that all the details are clearly
              visible on the camera and follow the instructions.
            </p>
            <p
              style={{ textAlign: "justify", fontWeight: "bold" }}
              className={`${aadharFrontShow ? "d-show" : "d-none"}`}
            >
              1. Please show the front side of your original Aadhar Card.
            </p>
            <p
              style={{ textAlign: "justify", fontWeight: "bold" }}
              className={`${aadharBackShow ? "d-show" : "d-none"}`}
            >
              2. Please show the back side of your original Aadhar Card.
            </p>
            <p
              style={{ textAlign: "justify", fontWeight: "bold" }}
              className={`${panShow ? "d-show" : "d-none"}`}
            >
              3. Please show your original PAN Card.
            </p>
            <p
              style={{ textAlign: "justify", fontWeight: "bold" }}
              className={`${askForDetails ? "d-show" : "d-none"}`}
            >
              4. Please speak your Full Name, Date of Birth and Permanent
              Address.
            </p>
            {start && (
              <Countdown
                date={Date.now() + counter * 1000}
                renderer={renderer}
              />
            )}
            {/* <p style={{ textAlign: "justify", fontWeight: "bold" }}>
              3. Please show your signature on blank page, signed using black
              pen.
            </p> */}
            <Form className="mt-4">
              {/* <h5 className="p-0 m-0">Take your Video</h5> */}
              <div>
                {isVideoRecording && (
                  <p style={{ color: "#ff0000" }}>Recording...</p>
                )}
                <div
                  style={{
                    width: 300,
                    height: 300,
                    display: cameraLoading ? "block" : "none",
                  }}
                >
                  <CameraLoader />
                </div>
                <div style={{ display: cameraLoading ? "none" : "block" }}>
                  <canvas
                    ref="canvas"
                    width={300}
                    height={300}
                    style={{
                      position: "absolute",
                      width: 300,
                      height: 300,
                      display: mediaBlob === null ? "block" : "none",
                    }}
                  ></canvas>
                  <video
                    id="player"
                    autoPlay
                    style={{
                      width: 300,
                      height: 300,
                      display: mediaBlob === null ? "block" : "none",
                    }}
                    muted
                  ></video>
                </div>
              </div>
              {mediaBlob != null && (
                <div>
                  <video
                    src={
                      mediaBlob != null ? URL.createObjectURL(mediaBlob) : ""
                    }
                    width={300}
                    height={300}
                    controls
                  />
                </div>
              )}
              <div>
                {isUploading && (
                  <ProgressBar
                    style={{ marginTop: 10, marginBottom: 10 }}
                    variant="success"
                    now={now}
                    label={`${now}%`}
                  />
                )}
              </div>
              <br />
              <div>
                <Button
                  className="btn btn-sm"
                  disabled={disable}
                  onClick={() => {
                    indications();
                    this.setState({
                      disable: true,
                      counter: 8,
                      mediaBlob: null,
                      start: true,
                    });
                    const that = this;
                    setTimeout(function () {
                      that.recordVideo(that);
                    }, 500);
                  }}
                >
                  {mediaBlob != null ? "Retake Video" : "Take video"}
                </Button>
                <Button
                  className="btn btn-sm mx-2"
                  id="stop"
                  onClick={() => {
                    this.setState({
                      isVideoRecording: false,
                      aadharFrontShow: false,
                      aadharBackShow: false,
                      panShow: false,
                      askForDetails: false,
                      disable: false,
                    });
                    // this.stopCamera();
                  }}
                >
                  Stop Video
                </Button>
              </div>
              {/* <a href="" id="download">
                Download
              </a> */}
            </Form>
            <Form.Group controlId="formBasicCheckbox">
              {/* <Form.Check
                checked={termsChecked}
                onChange={(e) => {
                  console.log("terms", termsChecked, e.target.checked);
                  this.setState({
                    termsChecked: e.target.checked,
                  });
                }}
                type="checkbox"
                label="I have read the terms and conditions, I thereby agree to the same"
              /> */}
            </Form.Group>
            <Link to={`/VideoKyc`}>
              <Button
                className="my-4"
                variant="secondary btn btn-block"
                // type="submit"
                onClick={async (e) => {
                  e.preventDefault();
                  // if (mediaBlob == null) {
                  //   alert("You haven't recorded any video yet");
                  // } else if (termsChecked) {
                  //   alert("Please agree terms and conditions");
                  // } else {
                  //   this.setState({ isUploading: true, now: 0 });
                  //   var formData = new FormData();
                  //   formData.append("video", mediaBlob);
                  //   const token = localStorage.getItem("token");
                  //   const response = await axios.post(
                  //     "/api/video/submitrecord/",
                  //     formData,
                  //     config
                  //   );
                  //   if (response.status == 200) {
                  //     alert("Your video kyc is done successfully");
                  //   } else {
                  //     alert("Problem occurred while uploading video.");
                  //   }
                  // }

                  // ABOVE CODE IS WORKING
                  this.setState({ videoSend: true });
                  setTimeout(() => this.handleConnectionChange(), 2000);

                  // navigator.serviceWorker.ready.then(function(swRegistration) {
                  //         console.log(swRegistration)
                  //         if(swRegistration.sync){
                  //             console.log("in sync")
                  //             swRegistration.addEventListener('sync', function(event) {
                  //                 console.log(event);
                  //                 // if (event.tag == 'myFirstSync') {
                  //                 //     console.log("Hello");
                  //                 //     event.waitUntil(alert("Hello"));
                  //                 // }
                  //             });
                  //             return swRegistration.sync.register('myFirstSync');
                  //         }
                  //   });
                  // const registration = await navigator.serviceWorker.ready;
                  // if ('periodicSync' in registration) {
                  //     try {
                  //         await registration.periodicSync.register('content-sync', {
                  //         // An interval of one day.
                  //         minInterval: 24 * 60 * 60 * 1000,
                  //         });
                  //     } catch (error) {
                  //         console.log(error)
                  //         // Periodic background sync cannot be used.
                  //     }
                  // }
                  // if( 'serviceWorker' in navigator && 'SyncManager' in window){
                  //     navigator.serviceWorker.ready.then(function(reg){
                  //         console.log(reg);
                  //         return reg.sync.register('tag_name')
                  //     }).catch(async function(e){
                  //         console.log(e);
                  //         const response = await axios.post(
                  //             "https://5938e510f267.ngrok.io/api/submitvideo/",
                  //             formData,
                  //             config
                  //         );
                  //     })
                  // }else{
                  //     alert("later");
                  // }
                  // if('serviceWorker' in navigator && 'SyncManger' in window){
                  //     navigator.serviceWorker.ready(then(function(reg){
                  //         reg.addEventListener('sync', function(event){
                  //             console.log(event);
                  //             if(event.tag=='tag_name'){
                  //                 event.waitUntil(async function(){
                  //                     const response = await axios.post(
                  //                         "https://5938e510f267.ngrok.io/api/submitvideo/",
                  //                         formData,
                  //                         config
                  //                     );
                  //                 })
                  //             }
                  //         })
                  //     }))
                  // }
                  // console.log(response);
                }}
              >
                Submit
              </Button>
            </Link>
          </Col>
        </Row>
        <Modal
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
        >
          <Modal.Body>
            <h4>VideoKYC Completed Aknowledgement</h4>
            <p>
              Your video kyc is done successfully
              <br />
              <br />
              Thank You.
            </p>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center align-items-center">
            <div className="text-center">
              <Button
                onClick={() => {
                  window.location.href =
                    "https://kyc.scalligent.tech/dashboard";
                }}
              >
                OK
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

export default VideoKyc;
