import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as actions from "../actions";
import LoadingComponent from "../components/LoadingComponent";

const Verify = () => {
  const form = useRef(null);
  let [loading, setLoading] = useState(false);
  const [aadharFront, setAadharFront] = useState(null);
  const [aadharBack, setAadharBack] = useState(null);
  const [pan, setPan] = useState(null);
  const username = localStorage.getItem("username");
  const email = localStorage.getItem("email");

  if (loading) {
    return <LoadingComponent />;
  }

  const handleUpdate = async (e) => {
    e.preventDefault();
    console.log("Aadhar and Pan update!");
    const formData = new FormData(form.current);
    if (formData.get("aadhar_card_front").size < 0 || formData.get("aadhar_card_back").size < 0 || formData.get("pan_card").size < 0) {
      alert("Upload both the documents");
      return;
    } else {
      setLoading(true);
      const response = await actions.submitRecord(formData);
      if (response) {
        window.location.href = "/language";
      } else {
        setLoading(false);
        alert("Upload correct documents");
      }
    }
  }

  return (
    <Container>
      <Row>
        <Col
          xs={12}
          sm={{ span: 8, offset: 2 }}
          md={{ span: 6, offset: 3 }}
          lg={{ span: 4, offset: 4 }}
          style={{ paddingTop: "20vh" }}
        >
          <Form ref={form} onSubmit={(e) => handleUpdate(e)}>
            <h4 style={{ marginBottom: 20 }}>Upload Documents</h4>
            <Form.Group className="mb-3">
              <Form.Label>Upload Aadhar Card Front</Form.Label>
              <Form.Control
                name="aadhar_card_front"
                // required
                type="file"
                onChange={(e) => {
                  setAadharFront(URL.createObjectURL(e.target.files[0]));
                }}
              />
            </Form.Group>
            <Image className="my-3" src={aadharFront} style={{ maxWidth: 410, maxHeight: 210 }} />
            <Form.Group className="mb-3">
              <Form.Label>Upload Aadhar Card Back</Form.Label>
              <Form.Control
                name="aadhar_card_back"
                // required
                type="file"
                onChange={(e) => {
                  setAadharBack(URL.createObjectURL(e.target.files[0]));
                }}
              />
            </Form.Group>
            <Image className="my-3" src={aadharBack} style={{ maxWidth: 410, maxHeight: 210 }} />
            <Form.Group className="mb-3">
              <Form.Label>Upload PAN Card</Form.Label>
              <Form.Control
                name="pan_card"
                // required
                type="file"
                onChange={(e) => {
                  setPan(URL.createObjectURL(e.target.files[0]));
                }}
              />
              <Image className="my-3" src={pan} style={{ maxWidth: 410, maxHeight: 210 }} />
            </Form.Group>
            <Button
              className="my-4"
              variant="secondary"
              className="w-100"
              type="submit"
            >
              Submit Documents
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Verify;
