import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  Modal,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import * as actions from "../actions";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/react";
import LoadingComponent from "../components/LoadingComponent";

const OpenAccountRequest = () => {
  const form = useRef(null);
  let [loading, setLoading] = useState(false);
  let [step, setStep] = useState(0);
  // const [username, setUsername] = useState("");
  // const [name, setName] = useState("");
  // const [dob, setDob] = useState("");
  // const [email, setEmail] = useState("");
  const [aadharFront, setAadharFront] = useState(null);
  const [aadharBack, setAadharBack] = useState(null);
  const [pan, setPan] = useState(null);

  const [show, setShow] = useState(false);

  if (loading) {
    return <LoadingComponent />;
  }

  if (!("mediaDevices" in navigator)) {
    // alert("Your browser/device does not support for videokyc.");
    return (
      <Container>
        <Row>
          <Col
            xs={12}
            sm={{ span: 8, offset: 2 }}
            md={{ span: 6, offset: 3 }}
            lg={{ span: 4, offset: 4 }}
            style={{ paddingTop: "40vh" }}
          >
            Your browser/device does not support for VideoKYC.
          </Col>
        </Row>
      </Container>
    );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(form.current);
    if (formData.get("mobile").length != 10) {
      alert("Enter correct mobile number");
    } else {
      setLoading(true);
      const response = await actions.createNewAccount(formData);
      if (response) {
        // window.location.href = "/documents";
        setShow(true);
        // alert(
        //   "Your details are successfully submitted. We will Sortly send you the Video-based Customer Identification Process (V-CIP) link on your registered mobile no to complete the onboarding process.\n\nThank You."
        // );
        setLoading(false);
      } else {
        setLoading(false);
        alert("Some problem occurred.");
      }
    }
  };

  const onHide = () => {
    setShow(false);
  };

  return (
    <Container
      className="py-5"
      fluid
      style={{ backgroundColor: "#DBEFFC", height: "100vh" }}
    >
      <Container>
        <Row>
          <Col
            xs={12}
            sm={{ span: 8, offset: 2 }}
            md={{ span: 6, offset: 3 }}
            lg={{ span: 8, offset: 2 }}
          >
            <Form ref={form} onSubmit={(e) => handleSubmit(e)}>
              <h4 style={{ marginBottom: 20 }}>Account Opening Form</h4>
              <Row>
                <Row
                  style={{
                    visibility: step == 0 ? "visible" : "hidden",
                    height: step == 0 ? "auto" : 0,
                  }}
                >
                  <Form.Group className="mb-3" as={Col} lg={6}>
                    <Form.Text className="text-muted">Full Name:</Form.Text>
                    <Form.Control
                      size="sm"
                      required
                      type="text"
                      name="name"
                      placeholder="Enter your Full Name"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col} lg={6}>
                    <Form.Text className="text-muted">Date of Birth:</Form.Text>
                    <Form.Control size="sm" required type="date" name="dob" />
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col} lg={6}>
                    <Form.Text className="text-muted">Mobile Number:</Form.Text>
                    <Form.Control
                      size="sm"
                      required
                      type="text"
                      name="mobile"
                      placeholder="Enter mobile"
                      maxLength="10"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col} lg={6}>
                    <Form.Text className="text-muted">Father's Name:</Form.Text>
                    <Form.Control
                      size="sm"
                      required
                      type="text"
                      name="father_name"
                      placeholder="Enter your Father's Name"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col} lg={6}>
                    <Form.Text className="text-muted">Local Address:</Form.Text>
                    <Form.Control
                      size="sm"
                      required
                      as="textarea"
                      name="local_address"
                      placeholder="Enter Address"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col} lg={6}>
                    <Form.Text className="text-muted">
                      Permanent Address:
                    </Form.Text>
                    <Form.Control
                      size="sm"
                      required
                      as="textarea"
                      name="permanent_address"
                      placeholder="Enter Address"
                    />
                    <Form.Text className="text-muted">
                      We'll never share your details with anyone else.
                    </Form.Text>
                  </Form.Group>
                  <Button
                    variant="secondary"
                    className="w-100 my-3"
                    onClick={() => {
                      setStep(1);
                    }}
                  >
                    Next
                  </Button>
                </Row>
                <Row
                  style={{
                    visibility: step == 1 ? "visible" : "hidden",
                    height: step == 1 ? "auto" : 0,
                  }}
                >
                  <Form.Group className="mb-3">
                    <Form.Label>Upload Aadhar Card Front</Form.Label>
                    <Form.Control
                      name="aadhar_card_front"
                      required
                      type="file"
                      onChange={(e) => {
                        setAadharFront(URL.createObjectURL(e.target.files[0]));
                      }}
                    />
                  </Form.Group>
                  <Image
                    className="my-3"
                    src={aadharFront}
                    style={{ maxWidth: 410, maxHeight: 210 }}
                  />
                  <Form.Group className="mb-3">
                    <Form.Label>Upload Aadhar Card Back</Form.Label>
                    <Form.Control
                      name="aadhar_card_back"
                      required
                      type="file"
                      onChange={(e) => {
                        setAadharBack(URL.createObjectURL(e.target.files[0]));
                      }}
                    />
                  </Form.Group>
                  <Image
                    className="my-3"
                    src={aadharBack}
                    style={{ maxWidth: 410, maxHeight: 210 }}
                  />
                  <Form.Group className="mb-3">
                    <Form.Label>Upload PAN Card</Form.Label>
                    <Form.Control
                      name="pan_card"
                      required
                      type="file"
                      onChange={(e) => {
                        setPan(URL.createObjectURL(e.target.files[0]));
                      }}
                    />
                    <Image
                      className="my-3"
                      src={pan}
                      style={{ maxWidth: 410, maxHeight: 210 }}
                    />
                  </Form.Group>
                  <Button
                    variant="secondary"
                    className="w-100 my-3"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Row>
              </Row>
            </Form>
          </Col>
        </Row>
        <Modal
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
        >
          <Modal.Body>
            <h4>Account Request Acknowledgement</h4>
            <p>
              Your details are successfully submitted. We will Sortly send you
              the Video-based Customer Identification Process (V-CIP) link on
              your registered mobile no to complete the onboarding process.
              <br />
              <br />
              Thank You.
            </p>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center align-items-center">
            <div className="text-center">
              <Button
                onClick={() => {
                  window.location.href =
                    "https://kyc.scalligent.tech/dashboard";
                }}
              >
                OK
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </Container>
    </Container>
  );
};

export default OpenAccountRequest;
