import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as actions from "../actions";
import LoadingComponent from "../components/LoadingComponent";

const Verify = () => {
  const form = useRef(null);
  const [mobileOtp, setMobileOtp] = useState("");
  const [emailOtp, setEmailOtp] = useState("");
  let [loading, setLoading] = useState(false);
  const username = localStorage.getItem("username");
  const email = localStorage.getItem("email");

  if (loading) {
    return <LoadingComponent />;
  }

  const handleVerifyOtp = async(e) => {
    e.preventDefault();
    const formData = new FormData(form.current);
    formData.append("username", username);
    if (formData.get("otp").length != 6) {
      alert("Enter correct otp");
      return;
    } else {
      setLoading(true);
      const response = await actions.authLogin(formData);
      if (response) {
        window.location.href = "/agreement";
      } else {
        setLoading(false);
        alert("Entered invalid OTP");
      }
    }
  }

  return (
    <Container>
      <Row>
        <Col
          xs={12}
          sm={{ span: 8, offset: 2 }}
          md={{ span: 6, offset: 3 }}
          lg={{ span: 4, offset: 4 }}
          style={{ paddingTop: "20vh" }}
        >
          <Form ref={form} onSubmit={(e) => handleVerifyOtp(e)}>
            <h4 style={{ marginBottom: 20 }}>Verify</h4>
            <Form.Group className="mb-3">
              <Form.Text className="text-muted">OTP sent on your mobile number {username}</Form.Text>
              <Form.Control
                type="password"
                placeholder="Enter Mobile OTP"
                maxLength="6"
                name="otp"
              />
            </Form.Group>
            <Button
              className="my-4"
              variant="secondary"
              className="w-100"
              type="submit"
            >
              Verify OTP
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Verify;
