import React, { useState, useEffect } from "react";
import { Form, Dropdown } from "react-bootstrap";
import * as actions from "../actions";

const Question = () => {
  const [questions, setQuestions] = useState([]);

  useEffect(async () => {
    const response = await actions.getQuestions;
    if (response) {
      setQuestions(response);
    }
  });

  return (
    <div>
      <div className="d-flex ">
        <div>1.&nbsp;</div>
        <div>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's
        </div>
        <br />
      </div>
      <div>
        <Form.Check type="radio" label="Marathi" id="Marathi" name="language" />
        <Form.Check type="radio" label="Marathi" id="Marathi" name="language" />
      </div>
      <Dropdown.Divider />
    </div>
  );
};

export default Question;
