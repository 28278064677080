import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as actions from "../actions";
import LoadingComponent from "../components/LoadingComponent";

const Language = () => {
  const [value, setValue] = useState("English");
  let [loading, setLoading] = useState(false);

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <Container>
      <Row>
        <Col
          xs={{ span: 10, offset: 1 }}
          sm={{ span: 8, offset: 2 }}
          md={{ span: 6, offset: 3 }}
          lg={{ span: 4, offset: 4 }}
          style={{ paddingTop: "20vh" }}
        >
          <Form>
            <h4 style={{ marginBottom: 20 }}>Select your preffered language</h4>
            <div onChange={(e) => setValue(e.target.value)}>
              <Form.Check
                type="radio"
                label="English"
                id="English"
                name="language"
                value="English"
                defaultChecked={true}
              />
              <Form.Check
                type="radio"
                label="Hindi"
                id="Hindi"
                value="Hindi"
                name="language"
              />
              <Form.Check
                type="radio"
                label="Marathi"
                id="Marathi"
                value="Marathi"
                name="language"
              />
              <Form.Check
                type="radio"
                label="Gujarati"
                id="Gujarati"
                value="Gujarati"
                name="language"
              />
              <Form.Check
                type="radio"
                label="Punjabi"
                id="Punjabi"
                value="Punjabi"
                name="language"
              />
              <Form.Check
                type="radio"
                label="Bangla"
                id="Bangla"
                value="Bangla"
                name="language"
              />
              <Form.Check
                type="radio"
                label="Tamil"
                id="Tamil"
                value="Tamil"
                name="language"
              />
            </div>

            <Button
              className="my-4"
              variant="secondary btn btn-block"
              type="submit"
              onClick={async (e) => {
                e.preventDefault();
                console.log(value);
                if (value == "" || value == null) {
                  alert("Choose correct language");
                } else {
                  setLoading(true);
                  var formData = new FormData();
                  formData.append("language", value);
                  console.log(formData.get("language"));
                  const response = await actions.submitRecord(formData);
                  if (response) {
                    window.location.href = "/agreement";
                  } else {
                    setLoading(false);
                    alert("Select correct language");
                  }
                }
              }}
            >
              Proceed
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Language;
