import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Question from "../components/Question";
import LoadingComponent from "../components/LoadingComponent";

const Questions = () => {
  const [loading, setLoading] = setLoading(false);

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <Container>
      <Row>
        <Col
          xs={{ span: 12, offset: 0 }}
          sm={{ span: 8, offset: 2 }}
          md={{ span: 6, offset: 3 }}
          lg={{ span: 4, offset: 4 }}
        >
          <h4 style={{ marginBottom: 20 }}>Answer following Questions</h4>
          {/* <Form>
            <h4 style={{ marginBottom: 20 }}>Answer following Questions</h4>
            <Form.Check
              type="radio"
              label="English"
              id="English"
              name="language"
            />
            <Form.Check type="radio" label="Hindi" id="Hindi" name="language" />
            <Form.Check
              type="radio"
              label="Marathi"
              id="Marathi"
              name="language"
            />
            <Link to={`/agreement`}>
              <Button
                className="my-4"
                variant="secondary btn btn-block"
                type="submit"
              >
                Proceed
              </Button>
            </Link>
          </Form> */}
        </Col>
        <Col
          xs={{ span: 12, offset: 0 }}
          sm={{ span: 8, offset: 2 }}
          md={{ span: 6, offset: 3 }}
          lg={{ span: 4, offset: 4 }}
        >
          <Question />
          <Question />
          <Link to={`/agreement`}>
            <Button
              className="my-4"
              variant="secondary btn btn-block"
              type="submit"
            >
              Proceed
            </Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default Questions;
