import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App2";
import axios from "axios";
import * as constants from "./constants";
import "bootstrap/dist/css/bootstrap.min.css";


// axios.defaults.baseURL = constants.base_url;
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
// axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');

// background sync
// import {BackgroundSyncPlugin} from 'workbox-background-sync';
// import {registerRoute} from 'workbox-routing';
// import {NetworkOnly} from 'workbox-strategies';

// const bgSyncPlugin = new BackgroundSyncPlugin('myQueueName', {
//   maxRetentionTime: 24 * 60 // Retry for max of 24 Hours (specified in minutes)
// });

// registerRoute(
//   `https://5938e510f267.ngrok.io/api/submitvideo/`,
//   new NetworkOnly({
//     plugins: [bgSyncPlugin]
//   }),
//   'POST'
// );

///////////////

const onpush = function (event) {
  console.log(event.data);
  // From here we can write the data to IndexedDB, send it to any open
  // windows, display a notification, etc.
};

if ("serviceWorker" in navigator) {
  // Use the window load event to keep the page load performant
  window.addEventListener("load", () => {
    navigator.serviceWorker.register("/service-worker.js");
  });
}

// navigator.serviceWorker.register('./src/sw.js')

// Use serviceWorker.ready to ensure that you can subscribe for push
//   navigator.serviceWorker.ready.then(
//     function(serviceWorkerRegistration) {
//       var options = {
//         userVisibleOnly: true
//       };
//       serviceWorkerRegistration.pushManager.subscribe(options).then(
//         function(pushSubscription) {
//           console.log(pushSubscription.endpoint);
//           // The push subscription details needed by the application
//           // server are now available, and can be sent to it using,
//           // for example, an XMLHttpRequest.
//         }, function(error) {
//           // During development it often helps to log errors to the
//           // console. In a production environment it might make sense to
//           // also report information about errors back to the
//           // application server.
//           console.log(error);
//         }
//       );
//     });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
