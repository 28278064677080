import * as constants from "../constants";
import axios from "axios";

const config = {
  onUploadProgress: function (progressEvent) {
    var percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    console.log(percentCompleted);
  },
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("token"),
  },
};

export const authSendOTP = async (data) => {
  const response = await axios.post("/api/auth/sendotp/", data);
  if (response.status == 200) {
    console.log("OTP Sent on your mobile number and email");
    localStorage.setItem("username", data["username"]);
    return true;
  } else {
    return false;
  }
};

export const authLogin = async (data) => {
  const response = await axios.post("/api/auth/otp-verify/", data);
  if (response.status == 200) {
    console.log("OTP verified");
    localStorage.setItem("token", "Token " + response.data.token);
    localStorage.setItem("username", response.data.username);
    return true;
  } else {
    return false;
  }
};

export const submitRecord = async (formdata) => {
  const token = localStorage.getItem("token");
  console.log(formdata, token);
  const response = await axios.post(
    "/api/video/submitrecord/",
    formdata,
    config
  );
  if (response.status == 200) {
    return true;
  } else {
    return false;
  }
};

export const getQuestions = async () => {
  const response = await axios.get("/api/questions/");
  if (response.status == 200) {
    return response.data;
  } else {
    return false;
  }
};

export const createNewAccount = async (data) => {
  const response = await axios.post("/api/bankaccount/", data);
  if (response.status == 200) {
    // localStorage.setItem("mobile", data.get("mobile"));
    return true;
  } else {
    return false;
  }
};

export const updateAccount = async (data) => {
  const response = await axios.put("/api/bankaccount/", data);
  if (response.status == 200) {
    return true;
  } else {
    return false;
  }
};
