import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link, Redirect, useLocation } from "react-router-dom";
import NavBar from "./components/NavBar";
import Documents from "./screens/Documents";
import Language from "./screens/Language";
import Login from "./screens/Login";
import VideoKyc from "./screens/VideoKyc";
import Agreement from "./screens/Agreement";
import Questions from "./screens/Questions";
import Verify from './screens/Verify';
import { createBrowserHistory } from "history";
import OpenAccountRequest from './screens/OpenAccountRequest';
//import "../public/images/icons/favicon.ico";
//require("../public/images/icons/favicon.ico");

const history = createBrowserHistory();

const AppRoutes = () => {

  const [isAuthenticated, setAuthenticated] = useState(localStorage.getItem('token') != null ? true : false);

  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item != "");
  const param1 = params.length > 0 ? params[0] : "";

  return (
    <>
      {param1 != "" ? <NavBar /> : ''}
      <Routes>
        <Route path="" element={<Login />} />
        <Route path="/verify" element={<Verify />} />
        //<Route exact path="" element={<OpenAccountRequest />} />
        {isAuthenticated && (
          <Route>
            <Route path="/documents" element={<Documents />} />
            <Route path="/language" element={<Language />} />
            <Route path="/questions" element={<Questions />} />
            <Route path="/agreement" element={<Agreement />} />
            <Route path="/videokyc" element={<VideoKyc />} />
          </Route>
        )}
      </Routes>
    </>
  );
}

const App2 = () => {




  return (
    <Router history={history}>

      <AppRoutes />
    </Router>
  );
};

export default App2;
